export const SEO_SOCIAL_META = `
seo {
	metaDesc
	title
	opengraphAuthor
	opengraphTitle
	opengraphDescription
	opengraphImage {
		altText
		sourceUrl
		srcSet
	}
	opengraphUrl
	opengraphSiteName
	opengraphPublishedTime
	opengraphModifiedTime
	twitterTitle
	twitterDescription
	twitterImage {
		altText
		sourceUrl
		srcSet
	}
}
`;
