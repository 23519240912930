import { fetchAPI } from '../api';
import { BLOCKS_FRAGMENT } from '../graphql-fragments/gutenbergBlocks';
import { PAGE_META } from '../graphql-fragments/pageMeta';
import { SEO_SOCIAL_META } from '../graphql-fragments/seoSocialMeta';

export async function getHomePage() {
  const data = await fetchAPI(
    `
      query HomePage {
        pageBy(uri: "/") {
          id
          title
          excerpt
          content
          ${SEO_SOCIAL_META}
          ${BLOCKS_FRAGMENT}
        }
        ${PAGE_META}
      }
    `,
    {
      variables: {},
    }
  );

  return data;
}