export const PAGE_META = `
	generalSettings {
		title
	}
	themeSettings {
		themeSettings {
			footerLogo {
				mediaItemUrl
			}
			headerLogo {
				mediaItemUrl
			}
				newsletter {
				title
				content
			}
		}
	}
	topmenu: menu(id: "Top Menu", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
  menu: menu(id: "Header Menu", idType: NAME) {
    name
    menuItems(where: {parentId: 0}) {
      nodes {
        path
        label
        childItems(first: 50) {
          nodes {
            path
            label
            childItems(first: 50) {
              nodes {
                label
                path
              }
            }
          }
        }
      }
    }
    slug
  }
	footerColOneMenu: menu(id: "Footer Menu Col One", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
				description
			}
		}
		slug
	}
	footerColTwoMenu: menu(id: "Footer Menu Col Two", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
	footerColThreeMenu: menu(id: "Footer Menu Col Three", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
  footerConnectMenu: menu(id: "Footer Menu Connect", idType: NAME) {
    name
    menuItems {
      nodes {
        path
        label
      }
    }
    slug
  }
`;

export const PAGE_META_FINDINGS = `
	generalSettings {
		title
	}
	themeSettings {
		themeSettings {
			footerLogo {
				mediaItemUrl
			}
			headerLogo {
				mediaItemUrl
			}
			marginOfError
		}
	}
	topmenu: menu(id: "Top Menu", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
  menu: menu(id: "Header Menu", idType: NAME) {
    name
    menuItems(where: {parentId: 0}) {
      nodes {
        path
        label
        childItems(first: 50) {
          nodes {
            path
            label
            childItems(first: 50) {
              nodes {
                label
                path
              }
            }
          }
        }
      }
    }
    slug
  }
	footerColOneMenu: menu(id: "Footer Menu Col One", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
				description
			}
		}
		slug
	}
	footerColTwoMenu: menu(id: "Footer Menu Col Two", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
	footerColThreeMenu: menu(id: "Footer Menu Col Three", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
  footerConnectMenu: menu(id: "Footer Menu Connect", idType: NAME) {
    name
    menuItems {
      nodes {
        path
        label
      }
    }
    slug
  }
`;

export const PAGE_META_LISTING = `
	generalSettings {
		title
	}
	themeSettings {
		themeSettings {
			footerLogo {
				mediaItemUrl
			}
			headerLogo {
				mediaItemUrl
			}
			listingPageHeaders {
				page
				title
				content
				showCallbackButton
				requestCallbackButton {
					buttonText
					modalFormId
					modalText
					modalTitle
					title
				}
			}
			listingPageTestimonials {
				page
	          	quote
				quoteSource
				clickThroughLink {
					title
					url
				}
	          	image {
	            	sourceUrl
	            	mediaDetails {
		              	height
		              	width
	            	}
	          	}
			}
			newsletter {
				title
				content
			}
		}
	}
	topmenu: menu(id: "Top Menu", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
  menu: menu(id: "Header Menu", idType: NAME) {
    name
    menuItems(where: {parentId: 0}) {
      nodes {
        path
        label
        childItems(first: 50) {
          nodes {
            path
            label
            childItems(first: 50) {
              nodes {
                label
                path
              }
            }
          }
        }
      }
    }
    slug
  }
	footerColOneMenu: menu(id: "Footer Menu Col One", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
				description
			}
		}
		slug
	}
	footerColTwoMenu: menu(id: "Footer Menu Col Two", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
	footerColThreeMenu: menu(id: "Footer Menu Col Three", idType: NAME) {
		name
		menuItems {
			nodes {
				path
				label
			}
		}
		slug
	}
  footerConnectMenu: menu(id: "Footer Menu Connect", idType: NAME) {
    name
    menuItems {
      nodes {
        path
        label
      }
    }
    slug
  }
`;
